import { useParams } from "react-router-dom";
import { parseOptionalInt } from "../../../utils/Helpers";
import GeneralTable from "../../table/GeneralTable";
import useWriters from "../../../hooks/useWriters";
import { AwSection } from "../../awcomponents/AwSection";
import { TRACK_TABLE_COLUMNS } from "../../table/models/TrackTableColumns";

const WriterTracksTab = () => {
  const { writerId } = useParams();
  const { writerTracks, isLoadingTracks, writerTracksError } = useWriters(
    parseOptionalInt(writerId)
  );

  return (
    <AwSection
      title="Tracks"
      isLoading={isLoadingTracks}
      error={writerTracksError}
    >
      <GeneralTable
        columns={TRACK_TABLE_COLUMNS}
        data={writerTracks}
        filtering
        showPagination
        columnVisibility={{
          releaseDate: false,
          writers: false,
          audioLanguage: false,
          publishers: false,
          isCover: false,
        }}
      />
    </AwSection>
  );
};

export default WriterTracksTab;
