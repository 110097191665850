import { useState } from "react";
import ContentWrapper from "../../ContentWrapper";
import { AwSection } from "../../awcomponents/AwSection";
import GeneralTable from "../../table/GeneralTable";
import { useNavigate } from "react-router-dom";
import Modal from "../../modal/Modal";
import usePublishers from "../../../hooks/usePublishers";
import { PUBLISHER_TABLE_COLUMNS } from "../../table/models/PublisherTableColumns";
import PublisherForm from "../../forms/publisherform/PublisherForm";

const PublishersAdministration = () => {
  const { publishers, refetch, publishersLoading, publishersError } =
    usePublishers();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  return (
    <ContentWrapper title="Publishers">
      {showModal && (
        <Modal header="New publisher" onClose={() => setShowModal(false)}>
          <PublisherForm
            publisher={undefined}
            onSubmitCB={() => {
              refetch();
              setShowModal(false);
            }}
          />
        </Modal>
      )}
      <AwSection
        isLoading={publishersLoading}
        error={publishersError}
        action={
          <button
            className="secondary small"
            onClick={() => setShowModal(true)}
          >
            Add new publisher
          </button>
        }
      >
        <GeneralTable
          onRowClick={(row) => {
            navigate(`/administration/publishers/${row.original.id}`);
          }}
          columns={PUBLISHER_TABLE_COLUMNS}
          data={publishers}
          filtering
          showPagination
        />
      </AwSection>
    </ContentWrapper>
  );
};

export default PublishersAdministration;
