import { useEffect } from "react";

//Routing
import RootRoutes from "./router/AppRouter";

import { QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import axiosClient from "./clients/AxiosClient";
import queryClient from "./clients/ReactQueryClient";
import AuthProvider, { useAuth } from "./components/auth/AuthProvider";
import "./components/auth/firebase/firebase";
import ConfigProvider, { useConfig } from "./components/config/ConfigProvider";
import useMessage from "./hooks/useMessage";
import { addMessage } from "./redux/globalmessages/actions";
import { store } from "./redux/store";
import * as serviceWorker from "./serviceWorker";

serviceWorker.register({
  onUpdate: (registration) => {
    store.dispatch(
      addMessage({
        id: "new-version-available",
        title: "New version available!",
        type: "success",
        component: (
          <div>
            <p className="mb-2">Please reload page or brace for malfunction</p>
            <button className="mr-2" onClick={() => window.location.reload()}>
              Reload
            </button>
          </div>
        ),
      })
    );
  },
});

function App() {
  const { currentUser } = useAuth();
  const { showErrorMessage } = useMessage();
  const { baseUrl } = useConfig();

  useEffect(() => {
    if (!currentUser) return;
    axiosClient.defaults.baseURL = baseUrl;
    axiosClient.interceptors.request.use(
      async (config) => {
        const token = await currentUser.getIdToken();
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        showErrorMessage(
          "Authentication error",
          "Failed initializing authentication headers"
        );
        Promise.reject(error);
      }
    );
  }, [currentUser, baseUrl]);

  return <RootRoutes />;
}

const WrappedInAuthAndReduxContext = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider>
            <App />
          </ConfigProvider>
        </QueryClientProvider>
      </AuthProvider>
    </Provider>
  );
};

export default WrappedInAuthAndReduxContext;
