import { useNavigate } from "react-router-dom";

interface NavigateBackProps {
  toPath?: string;
}

const NavigateBackButton = ({ toPath }: NavigateBackProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="text-interactive hover:text-interactive-hover cursor-pointer mb-8"
      onClick={() => {
        toPath ? navigate(toPath) : navigate(-1);
      }}
    >
      <i className="fas fa-chevron-left" /> Back
    </div>
  );
};

export default NavigateBackButton;
