import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axiosClient from "../clients/AxiosClient";
import {
  setProduct as setProductAction,
  setTracks,
} from "../redux/product/actions";
import { selectProductState } from "../redux/product/selectors";
import { Product, ProductInput } from "../types/ProductTypes";
import { Track } from "../types/TrackTypes";
import {
  createThumbnail,
  getCoverArtBlobURL,
  getImageDimension,
  getThumbnailBlobURL,
} from "../utils/Helpers";
import { UploadInformation, useBlobUploader } from "../utils/useBlobUploader";
import useMessage from "./useMessage";

const useProduct = () => {
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const { showErrorMessage, showSuccessMessage } = useMessage();
  const dispatch = useDispatch();
  const { uploadRecursive } = useBlobUploader();

  const productState = useSelector(selectProductState());

  const getProduct = async (
    upc: string,
    useReduxGlobalState: Boolean = false,
    shouldFailSilently: boolean = false
  ) => {
    try {
      const product = await axiosClient
        .get<Product>(`/products/${upc}`)
        .then((res) => res.data);

      const tracks = await axiosClient
        .get<Track[]>(`/products/${upc}/tracks`)
        .then((res) => res.data);

      setProduct(product);
      if (useReduxGlobalState) {
        dispatch(setProductAction(product));
        dispatch(setTracks(tracks));
      }
    } catch (error) {
      if (!shouldFailSilently) {
        showErrorMessage("Failed", "Failed to fetch product!");
        throw error;
      }
    }
  };

  /**
   * Saves the cover art to the blob storage and updates the product with the cover art URL.
   * @param upc the upc of the product
   * @param image reference to the image file
   */
  const saveCoverArt = async (upc: number, image: File) => {
    const thumbnail = await createThumbnail(upc, image);
    const thumbnailURL = getThumbnailBlobURL(upc);
    const coverArtURL = getCoverArtBlobURL(upc);
    const coverArtDimension = (await getImageDimension(image)).toString();

    const coverArtUploadInfo: UploadInformation = {
      file: image,
      url: coverArtURL,
      metadata: {
        cacheControl: "public,max-age=2592000", //30 days cache in browser
        customMetadata: {
          dimension: coverArtDimension,
        },
      },
    };

    const thumbnailUploadInfo: UploadInformation = {
      file: thumbnail,
      url: thumbnailURL,
      metadata: {
        cacheControl: "public,max-age=2592000", //30 days cache in browser
        customMetadata: {
          dimension: coverArtDimension,
        },
      },
    };
    console.log("tjaa");

    uploadRecursive([coverArtUploadInfo, thumbnailUploadInfo])
      .then(() => {
        showSuccessMessage("Cover Art", "Cover art saved successfully!");
      })
      .catch((err) => {
        showErrorMessage(`Cover Art`, `Cover art failed to save!`);
        throw err;
      });
  };

  const saveProduct = (product: ProductInput): Promise<Product> =>
    axiosClient
      .post<Product>(`/products`, product)
      .then((res) => {
        showSuccessMessage(`Products`, `Products saved successfully!`);
        return res.data;
      })
      .catch((err) => {
        showErrorMessage(`Failed`, `Products failed to save!`);
        throw err;
      });

  return { productState, product, getProduct, saveProduct, saveCoverArt };
};

export default useProduct;
