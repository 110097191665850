import { ReactNode, useState } from "react";
import { DownArrow, ErrorSolid, UpArrow } from "../../utils/Icons";
import Loader from "../Loader";
export interface AwSectionProps {
  children?: ReactNode;
  title?: ReactNode;
  action?: JSX.Element;
  overrideColor?: string;
  expandable?: boolean;
  defaultExpanded?: boolean;
  largeTitle?: boolean;
  isLoading?: boolean;
  error?: Error | null;
}

export const AwSection = (props: AwSectionProps) => {
  const {
    action,
    title,
    children,
    overrideColor,
    expandable,
    defaultExpanded,
    largeTitle,
    isLoading,
    error,
  } = props;
  const [isExpanded, setisExpanded] = useState(
    defaultExpanded ? true : !expandable?.valueOf()
  );

  return (
    <div
      className={`w-full flex flex-col ${
        overrideColor ? overrideColor : "bg-white"
      } ${expandable ? "cursor-pointer" : ""} rounded-xl p-4 mb-4`}
      onClick={() => {
        if (expandable?.valueOf()) {
          setisExpanded(!isExpanded);
        }
      }}
    >
      {(title || action) && (
        <div className="grid gap-y-4 lg:flex wrap justify-between mb-4">
          <span
            className={` ${
              largeTitle ? "text-medium-title" : "text-lg"
            } font-semibold`}
          >
            {title}
          </span>
          <div className="flex">
            {action && action}
            {expandable?.valueOf() &&
              (isExpanded ? <UpArrow /> : <DownArrow />)}
          </div>
        </div>
      )}

      <div
        className={`flex flex-col gap-y-3 transition-all ease-in duration-200 ${
          isExpanded ? "block" : "hidden"
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {isLoading && <AwSectionLoading />}
        {error && <AwSectionError error={error} />}
        {!isLoading && !error && children}
      </div>
    </div>
  );
};

export const AwSubSection = (props: AwSectionProps) => {
  const { title, children, expandable } = props;
  const [isExpanded, setisExpanded] = useState(!expandable?.valueOf());
  return (
    <div
      className="hover:cursor-pointer border rounded-lg"
      onClick={() => {
        if (expandable?.valueOf()) {
          setisExpanded(!isExpanded);
        }
      }}
    >
      {title && (
        <div className="flex items-center justify-between p-4">
          <div className="font-medium flex items-center">
            <p>{title}</p>
          </div>
          {expandable?.valueOf() && (isExpanded ? <UpArrow /> : <DownArrow />)}
        </div>
      )}
      {isExpanded && (
        <div className="px-4 pb-4 bg-white rounded-lg">{children}</div>
      )}
    </div>
  );
};

const AwSectionLoading = () => {
  return (
    <div className="flex justify-center text-neutral-heavy mb-8">
      <Loader large />
    </div>
  );
};

const AwSectionError = (props: { error: Error }) => {
  return (
    <div className="flex flex-wrap justify-center gap-4 items-center mb-8">
      <ErrorSolid className="text-error" />
      <span className="text-regular-body-bold">{props.error.message}</span>
    </div>
  );
};
